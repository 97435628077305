<template>
    <b-navbar toggleable="lg" class="navbar-light bg-fd" >
        <b-navbar-brand href="#" class="text-white-1"><i class="fas fa-bars"></i>  {{title}}</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

            <ul class="navbar-nav mr-auto" >
                <li v-for="item in items" :key="item.key">

                    <button
                        v-if="typeof item.onclick === 'function'"
                        class="nav-link text-white-1 no-btn"
                        @click="item.onclick"> {{item.value}}</button>
                    <router-link v-else class="nav-link text-white-1"
                                 :class="[ $route.params.pathMatch.indexOf(item.key) > -1 ? 'active' : '']"
                                 :to="item.to"> {{item.value}}</router-link>
                </li>
            </ul>
            <ul class="navbar-nav " >
                <li>
                    <span class="navbar-text text-white-1 pr-1"><i class="far fa-address-card"></i> {{$store.getters.getUser.email}}</span>
                </li>
<!--                <li>-->
<!--                    <span class="navbar-text text-white-1 pr-1"><i class="fas fa-coins"></i> ยอดเงินคงเหลือ 10,000 บาท</span>-->
<!--                </li>-->
            </ul>
        </b-collapse>
    </b-navbar>
</template>

<script>
import {BNavbar,BNavbarToggle,BNavbarBrand} from 'bootstrap-vue/src/components/navbar'
import {BCollapse} from 'bootstrap-vue/src/components/collapse'
export default {
    props:['items','title'],
    name: "SubNav",
    components:{
        BNavbar,
        BNavbarToggle,
        BNavbarBrand,
        BCollapse
    }
}
</script>

<style scoped>

</style>