<template>
    <div class="container container-xxl mt-5 ">
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 mb-3">
                <Nav :current-name="currentName" class="d-none d-md-block"></Nav>
                <NavSM :current-name="currentName" class="d-block d-md-none"></NavSM>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12">
                <div class="card glass">

                    <SubNav :items="items" :title="title"></SubNav>
                    <div class="card-body">
                        <slot></slot>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Nav from  './Nav'
import NavSM from "@/components/NavSM";
import SubNav from "@/components/SubNav";


export default {
    props:['currentName','items','title'],
    name: "Container",
    components:{
        SubNav,
        Nav,
        NavSM
    }
}
</script>

<style scoped>

</style>