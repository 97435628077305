<template>
    <div class="card glass">

        <b-navbar toggleable="lg" class="navbar-light bg-fd" >
            <b-navbar-brand href="#" class="text-white-1"><i class="fas fa-bars"></i>  Menu</b-navbar-brand>

            <b-navbar-toggle target="nav-collapseSM" class="text-white-1"></b-navbar-toggle>

            <b-collapse id="nav-collapseSM" is-nav>
                <span class="pl-2 spanB"> Menu</span>
                <router-link  to="/home" class="nav-link text-white-1 text-zoom" :class="[currentName === 'Home' ? 'active' :'' ]"  data-toggle="pill" href="#" role="tab" aria-selected="true"><i class="fas fa-home pr-2 pb-1"></i> Home</router-link>
                <router-link  to="/api-document" class="nav-link text-white-1 text-zoom" :class="[currentName === 'API-Document' ? 'active' :'' ]"  data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-compress-alt pr-2 pb-1"></i> API / Document</router-link>
                <span class="pl-2  pt-2 spanB"> Products</span>
                <router-link to="/products/scb" class="nav-link text-white-1 text-zoom" :class="[currentName === 'scb' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-university pr-2 pb-1"></i> SCB</router-link>
                <a class="nav-link disabled" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-university pr-2 pb-1"></i> KMA</a>
                <a class="nav-link disabled" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-university pr-2 pb-1"></i> Kbank</a>
                <a class="nav-link disabled" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fab fa-google-wallet pr-2 pb-1"></i> TrueWallet</a>
                <router-link to="/products/all-app" class="nav-link text-white-1 text-zoom" :class="[currentName === 'All Apps' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false" ><i class="fas fa-th pr-2 pb-1"></i> All Apps</router-link>

                <span class="pl-2 pt-2  spanB"> User</span>
                <router-link to="/user/billing" class="nav-link text-white-1 text-zoom" :class="[currentName === 'Billing' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="far fa-credit-card pr-2 pb-1"></i> Billing</router-link>
                <router-link to="/user/settings" class="nav-link text-white-1 text-zoom" :class="[currentName === 'Settings' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="far fa-cog pr-2 pb-1"></i> Settings</router-link>
                <router-link to="/auth/signout" class="nav-link text-zoom text-colorxred"  data-toggle="pill"  role="tab" aria-selected="false"><i class="fas fa-sign-out-alt pr-2 pb-1"></i> SignOut</router-link>


            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import {BNavbar,BNavbarToggle,BNavbarBrand} from 'bootstrap-vue/src/components/navbar'
import {BCollapse} from 'bootstrap-vue/src/components/collapse'
export default {
    props:['currentName'],
    name: "NavSM",
    components:{
        BNavbar,
        BNavbarToggle,
        BNavbarBrand,
        BCollapse
    }
}
</script>

<style scoped>

</style>