<template>
    <div>
        <!--       <div class="col " >-->
        <!--           <div class=" card glass">-->
        <!--               <div class="card-body">-->
        <!--                   <div class="text-center">-->
        <!--                       <div class="img-circle text-center mb-3">-->
        <!--                           <img src="../assets/img/btuplogo.jpg" alt="Image" class="shadow">-->
        <!--                       </div>-->
        <!--                       <h4 class="text-center">Jennifer Danlakhen</h4>-->
        <!--                       <p>{{ $store.getters.getUser.email }}</p>-->
        <!--                       <p>ยอดค้างชำระ 5000 บาท</p>-->
        <!--                       <p>จำนวน License ที่ถือครอง 1 ใบ</p>-->
        <!--                   </div>-->

        <!--                   <div class="nav flex-column nav-pills fs-1 p-3" >-->

        <!--                   </div>-->
        <!--               </div>-->
        <!--           </div>-->
        <!--       </div>-->
        <div class=" card glass">
            <div class="card-body">
                    <div class="nav flex-column nav-pills fs-1" >
                    <span class="pl-2 spanB"> Menu</span>
                    <router-link  to="/home" class="nav-link text-white-1 text-zoom" :class="[currentName === 'Home' ? 'active' :'' ]"  data-toggle="pill" href="#" role="tab" aria-selected="true"><i class="fas fa-home pr-2 pb-1"></i> Home</router-link>
                    <router-link  to="/api-document" class="nav-link text-white-1 text-zoom" :class="[currentName === 'API-Document' ? 'active' :'' ]"  data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-compress-alt pr-2 pb-1"></i> API / Document</router-link>
                    <span class="pl-2  pt-2 spanB"> Products</span>
                    <router-link to="/products/scb" class="nav-link text-white-1 text-zoom" :class="[currentName === 'scb' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-university pr-2 pb-1"></i> SCB</router-link>
                    <a class="nav-link disabled" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-university pr-2 pb-1"></i> KMA</a>
                    <a class="nav-link disabled" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fas fa-university pr-2 pb-1"></i> Kbank</a>
                    <a class="nav-link disabled" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="fab fa-google-wallet pr-2 pb-1"></i> TrueWallet</a>
                    <router-link to="/products/all-app" class="nav-link text-white-1 text-zoom" :class="[currentName === 'All Apps' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false" ><i class="fas fa-th pr-2 pb-1"></i> All Apps</router-link>

                    <span class="pl-2 pt-2  spanB"> User</span>
                    <router-link to="/user/billing" class="nav-link text-white-1 text-zoom" :class="[currentName === 'Billing' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="far fa-credit-card pr-2 pb-1"></i> Billing</router-link>
                    <router-link to="/user/settings" class="nav-link text-white-1 text-zoom" :class="[currentName === 'Settings' ? 'active' :'' ]" data-toggle="pill" href="#" role="tab" aria-selected="false"><i class="far fa-cog pr-2 pb-1"></i> Settings</router-link>
                    <router-link to="/auth/signout" class="nav-link text-zoom text-colorxred"  data-toggle="pill"  role="tab" aria-selected="false"><i class="fas fa-sign-out-alt pr-2 pb-1"></i> SignOut</router-link>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name :"Nav",
    props:['currentName'],
    data(){
        return {

        }
    }

}
</script>